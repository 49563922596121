import React from 'react'
import Row from 'antd/es/row'
import Form from 'antd/es/form'
import Input from 'antd/es/input'
import Button from 'antd/es/button'
import Card from 'antd/es/card'
import {ERROR, PENDING} from "../../../../models/globalStatuses";
import graph_1 from "../../../../assets/images/graph_1.png"
import background_image from "../../../../../src/assets/images/auth_background.jpg"
import graph_2 from "../../../../assets/images/graph_2.png"
import logo from '../../../../Layout/logotypes/Logo.svg'
import {Link} from "react-router-dom"
import style from "./style.css"
import * as url from "url";

const layout = {
	// labelCol: {span: 8},
	// wrapperCol: {span: 16},
};
const tailLayout = {
	wrapperCol: {offset: 11},
};

const leftStyle = {
	display: 'flex',
	width: '50%',
	flexDirection: 'column',
	backgroundColor: '#fff',
	paddingTop: '30px',
	paddingBottom: '30px',
	position: 'relative',
	height: '100vh'
}

const container = {
	margin: '0 auto',
	// width: '1360px',
/*	backgroundColor: '#3C64B1'*/
	backgroundColor: 'rgb(255, 255, 255)'
}


export const Auth = ({authStatus, onAuthorize}) => {

	const onFinish = ({username, password}) => {
		onAuthorize(username, password)
	};

	return (
		<div style={{...container}} id="auth_login">
			<Row align={'middle'} justify={'space-around'}  className="row" style={{alignItems: 'flex-start'}}>
				<div className="left" style={{...leftStyle}}>
					<div className="authBoxLeft" style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%'
					}}>
						<div className="header" style={{fontSize: '14px', fontWeight: 'bold', color: '#3C64B1'}} >

							{/*<span style={{fontSize: '24px', fontWeight: 'bold', color: '#3C64B1', marginRight: '5px', marginLeft: '60px'}}>
							FRONTIER |
						</span>
						   товарная аналитика*/}

							<a href={'https://saudata.kz/'} target={'_blank'}> <img style={{marginLeft: '60px'}} src={logo} alt={'logo'} className={'header_logo'}/></a>
						</div>
						<Card className='cardStyle'>
							<Form
								{...layout}
								initialValues={{remember: true}}
								onFinish={onFinish}
							>
								<p style={{
									textAlign: 'center',
									// fontSize: '28px',
									fontWeight: '700',
									margin: '0',
									fontFamily: 'Oswald'
								}} className='form-title'>
									Вход
								</p>
								<p className='login-advice'>
									Введите свои данные для входа на сервис
								</p>
								<Form.Item
									label="Email"
									name="username"
									className={'auth_input'}
									rules={[{required: true, message: 'Введите email'}]}
									validateStatus={authStatus === ERROR && "error"}
									style={{
										display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start'
									}}
								>
									<Input disabled={authStatus === PENDING} style={{
										width: '100%',
										borderRadius: '8px',
										padding: '7px'
									}}
									/>
								</Form.Item>

								<Form.Item
									label="Пароль"
									name="password"
									className={'auth_input'}
									rules={[{required: true, message: 'Введите пароль'}]}
									validateStatus={authStatus === ERROR && "error"}
									style={{
										display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start'
									}}
								>
									<Input.Password disabled={authStatus === PENDING}
													style={{
														width: '100%',
														borderRadius: '8px',
														padding: '7px'
													}}
									/>
								</Form.Item>
								<Form.Item {...tailLayout} >
									<Button type={authStatus === PENDING ? 'default' : 'primary'}
											htmlType="submit"
											disabled={authStatus === PENDING}
											loading={authStatus === PENDING}
											className='login-button'
									>
										Войти
									</Button>
								</Form.Item>

							</Form>
							{/*<a href="#" style={{*/}
							{/*	textAlign: 'center',*/}
							{/*	display: 'block',*/}
							{/*	color: "#3C64B1",*/}
							{/*	fontSize: '14px',*/}
							{/*	fontWeight: '700'*/}
							{/*}}*/}
							{/*>*/}
							{/*	Забыли пароль*/}
							{/*</a>*/}


							<div className="box">
								<p style={{
									fontSize: '14px',
									textAlign: 'center',
									color: '#545563',
									margin: '0',
								}}
								>
									Хотите ознакомиться с продуктом поближе?
								</p>
								<Link to={'/signup?token=demo'}
									  style={{
										  textAlign: 'center',
										  display: 'block',
										  color: "#292974",
										  fontSize: '14px',
										  fontWeight: '600'
									  }}
								>
									Получить Демо доступ
								</Link>
							</div>

						</Card>
						<div className="auth_bottom">
							{/*<p style={{*/}
							{/*	fontSize: '14px',*/}
							{/*	textAlign: 'center',*/}
							{/*	color: '#545563',*/}
							{/*	margin: '0',*/}
							{/*}}*/}
							{/*>*/}
							{/*	Хотите ознакомиться с продуктом поближе?*/}
							{/*</p>*/}
							{/*<Link to={'/signup?token=demo'}*/}
							{/*	  style={{*/}
							{/*		  textAlign: 'center',*/}
							{/*		  display: 'block',*/}
							{/*		  color: "#292974",*/}
							{/*		  fontSize: '14px',*/}
							{/*		  fontWeight: '600'*/}
							{/*	  }}*/}
							{/*>*/}
							{/*	Получить Демо доступ*/}
							{/*</Link>*/}
							<p style={{
								// position: 'fixed',
								// paddingTop: '20px',
								fontSize: '12px',
								color: '#545563',
								// marginLeft: '60px',
								// bottom: '2rem',
								marginTop: '10px',
								textAlign: 'center'
							}}
							>
								© TOO SAUDATA 2023
							</p>
						</div>
					</div>


				</div>
				<div className="right" style={{display: "flex", width: '50%', height: '100vh',backgroundImage: `url(${background_image})`,  backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
				}}>
					<div className="flex_row">
						<div className="graph_2">
							<img src={graph_2} alt=""/>
						</div>
						<div className="graph_1">
							<img src={graph_1} alt=""/>
						</div>
					</div>

				{/*	<div className="text">
						<div className="minititle">
							Узнайте больше о своем рынке
						</div>
						<div className="description">
							- Отслеживайте ценовые тренды рынка и ваших конкурентов
							<br/>
							- Отслеживайте тренды по объемам продаж, проводите оценку <br/> долей рынка
							<br/>
							- Проводите оценку своего потенциала в категории и <br/>популярность товара у потребителя
							<br/>
							- Совершенствуйте каналы продаж, оптимизируйте ассортимент <br/> и многое другое
						</div>
					</div>*/}



				</div>

			</Row>

		</div>
	)
};

