import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from "powerbi-client";
import Layout from "antd/es/layout/layout";
import {useParams} from "react-router-dom";
import {PageHeader} from "antd";
import {useEffect, useState} from "react";
import {productsActions} from "../../../models/productsModel/productsActions";
import Spin from "antd/es/spin";
import {connect} from "dva";
import {PENDING} from "../../../models/globalStatuses";
import FullscreenOutlined from '@ant-design/icons/FullscreenOutlined'
import FullscreenExitOutlined from '@ant-design/icons/FullscreenExitOutlined'


const UserInterfaceDashboardConnector = (props) => {

	const {
		dispatch,
		BiToken,
		CRUDStatus,
		products
	} = props

	const [fullSize, setFullSize] = useState(false)

	// debugger


	const {productId} = useParams()
	const current_product = products.data.filter(item => item.id == productId)[0]

	useEffect(() => {
		dispatch(productsActions.getBiAccessTokenFetch(productId))
		// FIXME
		// dispatch(productsActions.getPublicFetch(productId))


		document.title = 'Dashboard'
	}, [])

	const layoutStyle = {
		// width: '100%',
		width: '1300px',
	}



	const miniSizeStyle = {
		position: 'relative',
		background: '#fff',
		height: '80vh'
	}

	const fullSizeStyle = {
		position: 'fixed',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		zIndex: 1000,
		background: '#fff'
	}

	const buttonStyle = {
		position: 'absolute',
		top: '5px',
		right: '10px',
		cursor: 'pointer',
	}

	const iconStyle = {
		fontSize: '20px',
		color: '#fff',
		padding: '5px',
		backgroundColor: '#3c64b1',
		borderRadius: '5px'
	}

	const onChangeSize = () =>  {
		if (fullSize === true ) {
			setFullSize(false)
		} else {
			setFullSize(true)
		}
	}



	// const max


	const reportId = BiToken['reportId']
	const groupId = (BiToken['@odata.context'] || '').split('/')[6]
	let prepareEmbedUrl = `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`

	return (
		<Layout
			className={'contentLayout container'}
			style={layoutStyle}
		>
			<PageHeader
				className="site-page-header"
				style={{paddingLeft: '0', width: '1300px', margin: '0 auto'}}
				title={current_product?.name}
			/>
			<div>
			<div className="container" dangerouslySetInnerHTML={{__html: current_product?.description}}>

			</div>



			</div>
			{CRUDStatus === PENDING ? <Spin/> :
				<div
				style={fullSize === false ?  miniSizeStyle : fullSizeStyle}
				>
					<div
						style={buttonStyle}
						onClick={
							// fullSize === true ? setFullSize(false) : setFullSize(true)
							() => onChangeSize(fullSize)
						}
					>
						{
							fullSize === false ? 	<FullscreenOutlined  style={iconStyle}/>
							:
								<FullscreenExitOutlined style={iconStyle}/>

						}
					</div>



					{/*<div*/}
					{/*	style={{*/}
					{/*		width: '100%',*/}
					{/*		height: '43px',*/}
					{/*		position: 'absolute',*/}
					{/*		backgroundColor: '#262735',*/}
					{/*		border: '2px solid #585961',*/}
					{/*		borderBottom: 'none'*/}
					{/*	}}*/}
					{/*>*/}

					{/*</div>*/}

					{
						BiToken?.pb_public_path !== '' &&  BiToken?.pb_public_path !== null ? <iframe src={BiToken?.pb_public_path} style={{width: '100%', height: '100%'}} /> : <PowerBIEmbed
							embedConfig={
								{
									type: 'report', // Supported types: report, dashboard, tile, visual, and qna.
									id: reportId,
									embedUrl: prepareEmbedUrl,
									accessToken: BiToken.token,
									tokenType: models.TokenType.Embed, // Use models.TokenType.Aad if you're embedding for your organization.
									settings: {
										panes: {
											filters: {
												expanded: false,
												visible: false
											}
										},
									}
								}
							}

							eventHandlers={
								new Map([
									['loaded', function () {
										console.log('Report loaded');
									}],
									['rendered', function () {
										console.log('Report rendered');
									}],
									['error', function (event) {
										console.log(event.detail);
									}]
								])
							}

							cssClassName={
								"report-style-class"
							}

							getEmbeddedComponent={
								(embeddedReport) => {
									window.report = embeddedReport;
								}
							}
						/>
					}

				</div>

			}
		</Layout>
	)
}

function mapStateToProps(state) {

	return {
		BiToken: state.products.BiToken,
		CRUDStatus: state.products.CRUDStatus,
		products: state.products
	}
}

export const UserInterfaceDashboardContainer = connect(mapStateToProps)(UserInterfaceDashboardConnector);
