import Button from "antd/es/button";
import Modal from "antd/es/modal/Modal";
import {Row} from "antd";
import Form from "antd/es/form";
import Input from "antd/es/input";
import {Select, Switch} from "antd";
import React, {useEffect, useState} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Checkbox from "antd/es/checkbox";
import TextArea from "antd/es/input/TextArea";

const layout = {
	labelCol: {span: 4},
	wrapperCol: {span: 18},
};

const { Option } = Select;

export const ProductsModal = (props) => {
	const {
		createProductsModal,
		updateProductsModal,
		onSubmit,
		fields,
		onCancel,
		products,
		setEditorValue,
		editorValue,
		productTypes,
		editorValueFull,
		setEditorValueFull
	} = props

	const [currentType, setCurrentType] = useState('')


	const productTypesCustom = [
		{
			id: 1,
			type: 'files',
			name: 'Files',
		},
		{
			id: 2,
			type: 'dashboard',
			name: 'Dashboard',
		},
		{
			id: 3,
			type: 'map',
			name: 'Map',
		}
	]

	const optionsType = productTypesCustom?.map(item =>
		<Option key={item.id} value={item.type}>{item.name}</Option>
	)

	const formItem = {
		width: '30%',
		marginRight: '3%',
		display: 'block'
		// display: 'flex',
		// flexDirection: 'column'
	}
	const inputStyle = {
		borderRadius: '8px'
	}

	const onChangeType = (value) => {
		setCurrentType(value)
	}

	useEffect(() => {
		setCurrentType(updateProductsModal?.type)
	}, [updateProductsModal])

	return (
		<Modal title={updateProductsModal ? "Update Product" : "Добавить продукт"}
			   visible={updateProductsModal || createProductsModal}
			   destroyOnClose={true}
			   onCancel={onCancel}
			   style={{top: 30}}
			   footer={[
				   <div key={1}>
					   <Button onClick={onCancel} className='basic-btn' type={"primary"}>
						   Назад
					   </Button>
					   <Button form="productsForm" type={"primary"} htmlType="submit" className='basic-btn'>
						   Готово
					   </Button>
				   </div>
			   ]}
			   width={850}>
			<Form
				id="productsForm"
				{...layout}
				name="productsForm"
				fields={fields}
				onFinish={onSubmit}
				layout={"vertical"}
			>


				<Row justify={"space-between"}>
					<Form.Item
						label="Название"
						name="name"
						style={{...formItem, width: '30%'}}
						labelCol={{span: 24}}
						rules={[
							{
								required: true
							},
							() => ({
								validator(rule, value) {
									if (value && products.findIndex(item => item.name.toLowerCase() === value.toLowerCase() && item.id !== updateProductsModal.id) !== -1) {
										return Promise.reject(new Error('Enter unique name!'));
									}
									return Promise.resolve()
								}
							})
						]}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>
					<Form.Item
						label="Путь"
						name="path"
						labelCol={{span: 12}}
						style={{...formItem, width: '30%', marginRight: '0'}}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>
					<Form.Item
						label="Путь публичный"
						name="pb_public_path"
						labelCol={{span: 12}}
						style={{...formItem, width: '30%', marginRight: '0'}}
					>
						<Input style={{...inputStyle}}/>
					</Form.Item>

				</Row>


				<Row justify={"space-between"}>
					<Form.Item
						label="Тип"
						name="type"
						labelCol={{span: 24}}
						style={{...formItem}}
					>
						<Select
							showSearch
							placeholder="Select a person"
							optionFilterProp="children"
							onChange={onChangeType}
							style={{width: '100%'}}
						>
							{optionsType}
						</Select>
					</Form.Item>

					<Form.Item
						label="Демо"
						name="is_demo"
						initialValue={false}
						valuePropName="checked"
						labelCol={{span: 24}}
						style={{...formItem}}
					>
						<Checkbox/>
					</Form.Item>
				</Row>

				<Form.Item
					label="Описание"
					name="description"
					labelCol={{span: 24}}
					style={{...formItem, width: '70%'}}
				>
					{/*<TextArea*/}
					{/*	style={{*/}
					{/*		height: '200px',*/}
					{/*		borderRadius: '5px'*/}
					{/*	}}*/}
					{/*/>*/}
					<ReactQuill style={{minHeight: 200}} theme="snow" value={editorValue} onChange={setEditorValue}/>
				</Form.Item>

				<Form.Item
					label="Полное описание"
					name="description_full"
					labelCol={{span: 24}}
					style={{...formItem, width: '70%'}}
				>
					<TextArea
						style={{
							height: '200px',
							borderRadius: '5px'
						}}
					/>
					{/*<ReactQuill style={{minHeight: 200}} theme="snow" value={editorValueFull} onChange={setEditorValueFull}/>*/}
				</Form.Item>



			</Form>
		</Modal>
	)
}
